<script lang="ts" setup>
import TheHeader from "~/components/layout/TheHeader.vue";

const config = useGlobyConfig();

useHead({
  link: [{ rel: "icon", type: "image/png", href: "/img/favicon.png" }],
});
</script>

<template>
  <v-app>
    <the-header />
    <v-main>
      <router-view />
    </v-main>
    <v-footer
      class="bg-brown-darken-1"
      padless
      style="border-top: yellow 5px solid"
    >
      <v-container fluid>
        <v-row>
          <v-col
            align-self="center"
            class="d-inline-flex justify-center"
            cols="12"
            sm="4"
          >
            <nuxt-img
              alt=""
              src="/img/logo_full.png"
              style="max-height: 6rem"
            />
          </v-col>

          <v-col align-self="center" class="text-center" cols="12" sm="4">
            Copyright
            <a href="https://globy.app">Globy</a> {{ new Date().getFullYear() }}
          </v-col>
          <v-col align-self="center" class="text-center" cols="12" sm="4">
            Mail:
            <a
              :href="'mailto:' + config.organization.email.href"
              class="text-white"
              >{{ config.organization.email.text }}</a
            >
            <br />

            Telefoon:
            <a
              :href="'tel:' + config.organization.telephone.href"
              class="text-white"
              >{{ config.organization.telephone.text }}</a
            >
            <br />
            Privacy:
            <router-link class="text-white" target="_blank" to="/privacy">
              klik hier
            </router-link>
            <br />
            Algemene voorwaarden:
            <router-link class="text-white" target="_blank" to="/voorwaarden">
              klik hier
            </router-link>
            <br />
            KVK nummer: {{ config.organization.kvk_number }}
            <br />
            {{ config.organization.fullName }}
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<style scoped></style>
